:root {
  --primary-main: #473AFF;
  --primary-light: #6f64ff;
  --primary-dark: #3329c3;
  --primary-darkest: #251e87;
  --secondary-main: #f04bc4;
  --secondary-light: #e373c5;
  --error-main: #ff7189;
  --success-main: #19c37d;
  --info-main: #00a2ff;
  --warning-main: #e7c300;
  --warning-dark: #776713;
  --text-primary: #3d3d3d;
  --text-secondary: #8a7da4;
  --text-tertiary: #b2b2b2;
  --action-active: #8f85ff;
  --action-hover: #e0e0e0;
  --action-selected: #e0e0e0;
  --action-disabled: #949394;
  --action-disabled-background: #e0e0e0;
  --background-default: #473aff;
  --background-paper: #fff;
  --background-info: #fafbff;
  --warm-reply-main: #fc5b32;
  --divider: #E9E9E9;

  /* Keeping some specific variables that don't have direct equivalents */
  --tab-text-color: #b8b3ff;
  --tab-border-color: #1c167a;
  --router-border-color: #1c167a;
  --router-text-color: #fff;
  --field-hover-border-color: #15116b;
  --field-hover-shadow: rgba(42, 34, 163, 0.7);
  --label-color: #b8b3ff;
  --icon-color: #b8b3ff;
}

body {
  background: linear-gradient(
    90deg,
    var(--primary-darkest) 0%,
    var(--primary-dark) 50%,
    var(--primary-darkest) 100%
  );
}

a {
  text-decoration: none;
}

[data-amplify-authenticator].authenticator {
  background-color: var(--primary-main);
  --amplify-components-heading-color: white;
  --amplify-colors-brand-primary-80:var(--primary-main);
  --amplify-components-checkbox-icon-background-color: var(--primary-main);
  --amplify-components-fieldmessages-error-color: white;
  --amplify-components-text-color: white;

  .amplify-tabs-item {
    &[data-state="active"] {
      background: var(--primary-dark);
      border-color: var(--primary-main);
      color: var(--tab-text-color);
    }
    background-color: var(--primary-darkest);
    color: var(--tab-text-color);
    border-color: var(--tab-border-color);
    border: none;
  }
  [data-amplify-router] {
    background: var(--primary-dark);
    border-color: var(--primary-dark);
    color: var(--router-text-color);
    border-radius: 20px;
    overflow: hidden;
  }

  .amplify-input {
    background-color: var(--background-paper) !important;
    color: var(--text-primary);

    &:focus {
      border-color: transparent;
    }
  }

  .amplify-field-group {
    &:hover {
      border-color: var(--field-hover-border-color);
      box-shadow: 0 0 0 0.5rem var(--field-hover-shadow);
      border-radius: 8px;
    }
  }

  .amplify-label {
    color: var(--label-color);
  }
  .amplify-icon {
    color: var(--icon-color);
  }

  .amplify-button {
    background-color: var(--primary-dark);
    color: var(--background-paper);
    border-color: var(--primary-dark);
  }
  .amplify-button:hover,
  .amplify-button:focus,
  .amplify-button:active {
    background-color: var(--primary-main);
  }

  .amplify-button--primary {
    background-color: var(--primary-darkest);
  }

  .amplify-text {
    color: var(--amplify-components-text-color);
  }
  .amplify-text a {
    color: var(--amplify-components-text-color);
    text-decoration: underline;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.pulsate {
  animation: pulsate 2s infinite;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotate 2s infinite linear;
  transform-origin: center;
}